import React, {useRef, useMemo, forwardRef, useCallback} from 'react';
import {Page, ILayout, Iimg, Itext} from '../Interfaces/Interfaces';
import {LayoutHandle, PageHandle} from '../Handlers/Handlers';
import EditLayoutBox from '../Components/EditLayoutBox';
import Layout_0 from '../Components/Layouts/Layout_0';
import {useQuery} from '@apollo/client';
import {SubmitHandler} from '../Handlers/Handlers';
import {Pages} from '../Helpers/GqlHelper';

const Page0 = forwardRef<PageHandle, Props>(({mutate}, ref) => {
  const layout_0_ref = useRef<LayoutHandle | null>(null);
  const layouts_ref = useMemo(() => [layout_0_ref], []);

  const createDataMap = (queryPage: Page): Page => {
    queryPage.layouts?.forEach((layout) => {
      //imgs
      const imgMap = new Map<string, Iimg>();
      layout.imgs?.forEach((img) => imgMap.set(img.img_id, img));

      //texts
      const textMap = new Map<string, Itext>();
      layout.texts?.forEach((text) => textMap.set(text.text_id, text));

      //layouts
      queryPage.layoutsMap?.set(layout.layout_id, {
        layout_id: layout.layout_id,
        imgs: imgMap,
        texts: textMap
      });
    });

    layouts_ref.forEach((layout) => {
      if (layout.current && queryPage.layoutsMap) {
        const layout_id = layout.current.layout_id;
        layout.current.setLayoutData(queryPage.layoutsMap.get(layout_id));
      }
    });

    return queryPage;
  };

  const submit = useCallback<SubmitHandler>(
    (confirmed) => {
      mutate(layouts_ref);
    },
    [layouts_ref, mutate]
  );

  useQuery(Pages, {
    onCompleted: (data) =>
      createDataMap({
        layouts: data.pages[0].layouts,
        layoutsMap: new Map<string, ILayout>()
      }),
    onError: (data) => console.log(data)
  });

  return (
    <>
      <div className="flex flex-col items-center">
        <EditLayoutBox submit={submit} />
        <Layout_0 layout_id={'0'} ref={layout_0_ref} />
        <iframe
          width="100%"
          height="600"
          loading="lazy"
          allowFullScreen={true}
          src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ5b9Cux5_hkcRs4o_x8Mp80s&key=AIzaSyCDcaOoGzbwMx8HnFZ7ivVyn3DEA9yl9Pg"
        ></iframe>
      </div>
    </>
  );
});

export default Page0;

interface Props {
  id: string;
  mutate(data: React.MutableRefObject<LayoutHandle | null>[]): void;
}
