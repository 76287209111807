import {forwardRef, ReactNode, useImperativeHandle, useRef} from 'react';
import {Itext} from '../../Interfaces/Interfaces';
import {EditTextHandle} from '../../Handlers/Handlers';
import EditBox from '../EditBox';

const Editable_Text = forwardRef<EditTextHandle, Props>(({textStyle, children, text_id}, ref) => {
  const textRef = useRef<HTMLDivElement | null>() as React.MutableRefObject<HTMLDivElement | null>;
  const textDataRef = useRef<Itext | undefined>(undefined);

  useImperativeHandle(
    ref,
    () => ({
      text_id,
      getTextData: () => textDataRef.current,
      getValue: () => textRef.current?.innerHTML,
      setTextData: (textData) => {
        if (textData) {
          textDataRef.current = textData;
          if (textRef.current) textRef.current.innerHTML = textData.value;
        }
      }
    }),
    [text_id]
  );
  const buttonStyle = 'bg-white ml-2 hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow';

  return (
    <>
      <p ref={textRef} className={`${textStyle}`} contentEditable={true} suppressContentEditableWarning={true}>
        {children}
      </p>
    </>
  );
});

export default Editable_Text;

interface Props {
  text_id: string;
  textStyle: string;
  children?: ReactNode;
}
