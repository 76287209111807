import {forwardRef, ReactNode} from 'react';

const EditBox = forwardRef<EditBoxHandle, Props>(({children}, ref) => {
  return <div className="flex bg-gray-500">{children}</div>;
});

export default EditBox;

interface Props {
  children?: ReactNode;
}

export interface EditBoxHandle {
  id: string;
}
