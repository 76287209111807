import React, {useRef, forwardRef, useImperativeHandle, useMemo} from 'react';

import Editable_Img from '../../Components/Editables/Editable_Img';
import Editable_Text from '../../Components/Editables/Editable_Text';
import {LayoutHandle, EditImageHandle, EditTextHandle} from '../../Handlers/Handlers';

const Layout_0 = forwardRef<LayoutHandle, Props>(({layout_id}, ref) => {
  const img_0_ref = useRef<EditImageHandle | null>(null);
  const imgs_ref = useMemo(() => [img_0_ref], []);

  const text_0_ref = useRef<EditTextHandle | null>(null);
  const text_1_ref = useRef<EditTextHandle | null>(null);
  const texts_ref = useMemo(() => [text_0_ref, text_1_ref], []);

  useImperativeHandle(ref, () => ({
    layout_id,
    texts_ref,
    imgs_ref,
    setLayoutData: (layout) => {
      imgs_ref.forEach((img) => {
        if (img.current && layout && layout.imgs) {
          const imgId = img.current.img_id;
          img.current.setImageData(layout.imgs.get(imgId));
        }
      });

      texts_ref.forEach((text) => {
        if (text.current && layout && layout.texts) {
          const text_id = text.current.text_id;
          text.current.setTextData(layout.texts.get(text_id));
        }
      });
    }
  }));

  return (
    <>
      <div>
        <Editable_Img ref={img_0_ref} img_id={'1'}>
          <div className="flex flex-col items-center content-center justify-center h-[100vh]">
            <Editable_Text ref={text_0_ref} text_id={'0'} textStyle={'text-5xl xs:text-base'}>
              cccc
            </Editable_Text>
          </div>
        </Editable_Img>
      </div>
    </>
  );
});

export default Layout_0;

interface Props {
  layout_id: string;
}
