import {useState} from 'react';
import {Routes, Route} from 'react-router-dom';
import Page0 from './Pages/Page0';
import {LayoutHandle} from './Handlers/Handlers';
import {ApolloClient, ApolloProvider, InMemoryCache, HttpLink, NormalizedCacheObject, DefaultOptions} from '@apollo/client';
import {GetIMutateImages, GetIMutateTexts, GetMutation} from './Helpers/GqlHelper';
import {IMutateData} from './Interfaces/Interfaces';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};

const createApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    link: new HttpLink({
      uri: 'https://risto.hasura.app/v1/graphql',
      headers: {
        'x-hasura-admin-secret': 'APn8SUj07ESFjGzomlxAe8nepaSDKu2zoeqfgVZr6Ar3kcFmYEO16dsEO8Z3Znbd'
      }
    }),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions
  });
};

const App: React.FC = () => {
  const [client] = useState(createApolloClient());

  const MutateData = async (layouts: React.MutableRefObject<LayoutHandle | null>[]): Promise<void> => {
    const iMutateImages = await GetIMutateImages(layouts);
    const iMutateTexts = await GetIMutateTexts(layouts);

    const mutateData: IMutateData = {
      texts: iMutateTexts,
      imgs: iMutateImages
    };
    const mutation = GetMutation(mutateData);
    client.mutate({mutation: mutation}).then(() => client.refetchQueries({include: ['MyQuery']}));
  };

  const mutate = (layouts: React.MutableRefObject<LayoutHandle | null>[]): void => {
    MutateData(layouts);
  };

  return (
    <ApolloProvider client={client}>
      <Routes>
        <Route path="/" element={<Page0 id={'0'} mutate={mutate} />}></Route>
      </Routes>
    </ApolloProvider>
  );
};

export default App;
