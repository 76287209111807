import {DocumentNode, gql} from '@apollo/client';
import {LayoutHandle} from '../Handlers/Handlers';
import {IMutateData, IMutateImages, IimgToUpdate, IMutateTexts} from '../Interfaces/Interfaces';

export const Pages = gql`
  query MyQuery {
    pages(where: {user_id: {_eq: 0}, id: {_eq: 0}}) {
      id
      layouts {
        layout_id
        imgs {
          user_id
          page_id
          layout_id
          img_id
          value
        }
        texts {
          user_id
          page_id
          layout_id
          text_id
          value
          color
        }
      }
    }
  }
`;

export const GetMutation = (data: IMutateData): DocumentNode => {
  return gql`
  mutation update_many_texts {
    update_texts_many(updates: [${data.texts.text.update}]) {
      affected_rows
    }
    insert_texts(objects: [${data.texts.text.insert}]) {
      returning{
        value
      }
    }
    update_imgs_many(updates: [${data.imgs.imgs.update}]) {
      affected_rows
    }
    insert_imgs(objects: [${data.imgs.imgs.insert}]) {
      returning{
        value
      }
    }
  }
`;
};

export const updateImages = async (data: IimgToUpdate[]): Promise<IimgToUpdate[]> => {
  const url = 'https://risto-worker.94matteo1.workers.dev/';
  const devUrl = 'http://127.0.0.1:8787';
  const resp = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({data}),
    headers: {'Content-Type': 'application/json'}
  });
  return resp.json();
};

export const GetIMutateImages = async (layouts: React.MutableRefObject<LayoutHandle | null>[]): Promise<IMutateImages> => {
  const imgs: IimgToUpdate[] = [];
  let updateImg = '';
  let insertImg = '';

  layouts.forEach((layout) => {
    layout.current?.imgs_ref.forEach((img) => {
      if (img.current && layout.current) {
        imgs.push({
          imgExist: img.current.getImageData() !== undefined,
          Iimg: img.current.getImageData(),
          value: img.current.getValue(),
          imageCloudflareId: '0' + '-' + '0' + '-' + layout.current.layout_id + '-' + img.current.img_id,
          name: img.current.getImageName(),
          layout_id: layout.current.layout_id,
          img_id: img.current.img_id
        });
      }
    });
  });

  const updatedImagesData = await updateImages(imgs);

  updatedImagesData.forEach((updatedData) => {
    const data = updatedData.Iimg;
    if (data) {
      updateImg += `{where: {
        user_id: {_eq: ${data.user_id}},
        page_id: {_eq: "${data.page_id}"} ,
        layout_id: {_eq: "${data.layout_id}"},
        img_id: {_eq: "${data.img_id}"},
      },_set: {value: "${updatedData.name}"}},`;
    } else {
      insertImg += `{user_id: 0,page_id: "0",layout_id: "${updatedData.layout_id}",value: "${updatedData.name}", img_id:"${updatedData.img_id}"},`;
    }
  });
  return {
    imgs: {
      update: updateImg,
      insert: insertImg
    }
  };
};

export const GetIMutateTexts = async (layouts: React.MutableRefObject<LayoutHandle | null>[]): Promise<IMutateTexts> => {
  let updateText = '';
  let insertText = '';

  layouts.forEach((layout) => {
    layout.current?.texts_ref.forEach((text) => {
      if (text.current) {
        const data = text.current.getTextData();
        if (data) {
          const checkedChanges = data.value !== text.current.getValue();
          if (checkedChanges) {
            updateText += `{where: {
              user_id: {_eq: ${data.user_id}},
              page_id: {_eq: "${data.page_id}"} ,
              layout_id: {_eq: "${data.layout_id}"},
              text_id: {_eq: "${data.text_id}"},
            },_set: {value: "${text.current.getValue()}"}},`;
          }
        } else {
          if (layout.current) {
            const text_id = text.current.text_id;
            const layout_id = layout.current.layout_id;
            const value = text.current.getValue();
            insertText += `{user_id: 0,page_id: "0",layout_id: "${layout_id}",value: "${value}", color: "0", text_id:"${text_id}"},`;
          }
        }
      }
    });
  });

  return {
    text: {
      update: updateText,
      insert: insertText
    }
  };
};
