import {forwardRef, useRef, ReactNode, useImperativeHandle, ChangeEvent} from 'react';
import {Iimg} from '../../Interfaces/Interfaces';
import EditBox from '../EditBox';
import {EditImageHandle} from '../../Handlers/Handlers';

const Editable_Img = forwardRef<EditImageHandle, Props>(({children, img_id}, ref) => {
  const imgRef = useRef<HTMLDivElement | null>() as React.MutableRefObject<HTMLDivElement | null>;
  const imgName = useRef<string | undefined>();
  const cloudFareImgId = useRef<string | undefined>();

  const uploadedImageRef = useRef<string | ArrayBuffer | null>(null);

  const imgDataRef = useRef<Iimg | undefined>(undefined);

  const baseUrl = 'https://imagedelivery.net/fKWFpCOIPpPC3BowvUtGyg/';
  const endUrl = '/public';

  useImperativeHandle(ref, () => ({
    img_id,
    getValue: () => uploadedImageRef.current,
    getCloudFareImgId: () => cloudFareImgId.current,
    getImageName: () => imgName.current,
    getImageData: () => imgDataRef.current,
    setImageData: (imgData) => {
      if (imgData) {
        imgDataRef.current = imgData;
        if (imgRef.current) {
          cloudFareImgId.current = imgData.user_id + '-' + imgData.page_id + '-' + imgData.layout_id + '-' + imgData.img_id;

          const url = baseUrl + cloudFareImgId.current + '/' + endUrl;
          imgRef.current.style.backgroundImage = `url(${url + '?' + Date.now()})`;
        }
      }
    }
  }));

  const loadImage = (event: ChangeEvent<HTMLInputElement>): void => {
    const {files} = event.target;

    if (FileReader && files && files.length) {
      const fr = new FileReader();
      fr.onload = function () {
        if (imgRef.current) {
          uploadedImageRef.current = fr.result;
          imgName.current = files[0].name;
          imgRef.current.style.backgroundImage = `url(${fr.result})`;
        }
      };
      fr.readAsDataURL(files[0]);
    }
  };

  const buttonStyle = ' bg-white mr-2 hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow';

  return (
    <div className="">
      <div ref={imgRef} className="w-[100vw] h-[100vh] bg-no-repeat bg-cover bg-center ">
        {children}
      </div>
      <div className="w-full mt-[-50px] ">
        <EditBox>
          <input type="file" name="image" placeholder="Image" onChange={(e) => loadImage(e)} />
        </EditBox>
      </div>
    </div>
  );
});

export default Editable_Img;

interface Props {
  img_id: string;
  children?: ReactNode;
}
