import {forwardRef, useState} from 'react';
import {SubmitHandler} from '../Handlers/Handlers';

const EditLayoutBox = forwardRef<EditLayoutBoxHandle, Props>(({submit}, ref) => {
  const buttonStyle = 'bg-white ml-2 hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow';
  return (
    <>
      <div className="flex flex-wrap items-center w-full bg-gray-500 ">
        <button className={buttonStyle} onClick={() => submit(true)}>
          Submit
        </button>
        <button className={buttonStyle} onClick={() => submit(false)}>
          Revert
        </button>
        <button className={buttonStyle} onClick={() => submit(false)}>
          Colors
        </button>
      </div>
    </>
  );
});

export default EditLayoutBox;

interface Props {
  submit: SubmitHandler;
}

export interface EditLayoutBoxHandle {
  id: string;
}
